import * as yup from "yup";

export const timezones = {
  IST: {
    top: "54%",
    right: "32%"
  },
  PST: {
    top: "52.2%",
    right: "35%"
  },
  CST: {
    top: "40%",
    left: "25%"
  },
  EST: {
    top: "34%",
    left: "55%"
  },
  DST: {
    top: "57%",
    left: "48%"
  }
};

export const INPUT_HELPER_STYLE = {
  ".MuiFormHelperText-root": {
    fontWeight: 700,
    ml: "0.5rem !important",
    mt: "0.75rem !important"
  }
};

export const PRIMARY_BUTTON_STYLE = {
  color: "#00171F",
  background: "white",
  border: "5px solid #00171F",
  borderRadius: "0px",
  minWidth: "12.5rem",
  // margin: "0 0 0 17vw",
  padding: "1rem 4.625rem",
  height: "3.438rem",
  fontSize: "1.125rem",
  lineHeight: "1.688rem",
  fontWeight: 900,
  "&:hover": {
    background: "#00171F",
    color: "white",
    boxShadow: "none"
  }
};

export const SECONDARY_BUTTON_STYLE = {
  color: "#00171F",
  background: "white",
  border: "5px solid white",
  borderRadius: "0px",
  minWidth: "12.5rem",
  // margin: "0 0 0 17vw",
  padding: "1rem 4.625rem",
  height: "3.438rem",
  fontSize: "1.125rem",
  lineHeight: "1.688rem",
  fontWeight: 900,
  "&:hover": {
    background: "#00171F",
    border: "5px solid #00171F",
    color: "white",
    boxShadow: "none"
  }
};

export const TGS_WEBSITE_URL = "https://www.beta.teamgeeksolutions.com/";

export const validationSchema = {
  timezone: yup.string().required("Timezone is required").nullable(),
  requirements: {
    title: yup.string().required("Title is required").nullable(),
    no_of_folks_required: yup.string().required("No of folks required is required").nullable(),
    joining_time: yup.string().required("Joining time is required").nullable()
  },
  company: {
    first_name: yup
      .string()
      .required("First name is required")
      .nullable()
      .matches(/^[a-zA-Z]{3,}$/, "Please enter a valid first name"),
    last_name: yup
      .string()
      .required("Last name is required")
      .nullable()
      .matches(/^[a-zA-Z]{3,}$/, "Please enter a valid last name"),
    designation: yup.object().required("Designation is required").nullable(),
    industry: yup.object().required("Industry is required").nullable(),
    company_email_id: yup
      .string()
      .email("Please enter a valid email")
      .required("Company email id is required")
      .nullable()
  },
  company_name: yup
    .string()
    .required("Company is required")
    .nullable()
    .matches(/^[a-zA-Z ]{3,}$/, "Please enter a valid company name"),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(/^\+?[1-9][0-9]{7,14}$/, "Invalid Format")
    .nullable()
};

export const REQUIREMENT_DATA_STR = "requirement-data";
