import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { CustomButtonGroup } from "@teamgeeksolution/components-library";
import Map from "../../components/custom/map/Map";
import { getSkillDataTable } from "../hiringFormHelper";
import Requirement from "../../components/custom/requirement/Requirement";
import { useNavigateUtil } from "../../utils";
import { ROUTE_CONSTANTS } from "../../constants";

function Success() {
  const styles = {
    root: {
      fontWeight: 700
    },
    textStyle: {
      marginTop: "2rem"
    },
    otpButtonStyle: {
      color: "#003459",
      background: "white",
      marginLeft: "8.5vw",
      marginBottom: "4vh",
      border: "2px solid #003459",
      minWidth: "4rem",
      minHeight: "0.5rem"
    },
    secondaryButtonStyle: {
      "@media (max-width: 1400px)": {
        marginRight: "8px"
      },

      "@media (min-width: 1401px)": {
        marginRight: "36px"
      }
    },
    primaryButtonStyle: {
      // maxWidth: "11.5rem",
      // minWidth: "1vw !important",
    },
    sxPropsObj: {
      tableCell: {
        height: "64px"
      },
      tableRow: {
        "& td:last-of-type": {
          borderRadius: "0px 10px 10px 0px",
          border: "solid 1px #E6E6E6",
          borderLeft: "none"
        },
        "& td:first-of-type": {
          border: "solid 1px #E6E6E6",
          borderRadius: "10px 0px 0px 10px",
          borderRight: "none"
        }
      },
      table: {
        borderSpacing: "0 1rem"
      }
    },
    desc1: {
      lineHeight: "24px",
      marginBottom: "20px",
      textAlign: "justify"
    },
    desc2: {
      lineHeight: "20px",
      textAlign: "justify"
    },
    desc3: {
      lineHeight: "1.5rem",
      marginBottom: "1.25rem",
      textAlign: "justify",
      marginLeft: "0.9rem"
    }
  };

  const reduxProfileData = useSelector((state) => state.startHiring.profileData);
  const reduxUserData = useSelector((state) => state.startHiring.userData);

  const [skillTableData, setSkillTableData] = useState([]);

  const navigate = useNavigateUtil();

  const handlePrimaryButtonClick = () => {
    window.close();
    window.open("https://www.beta.teamgeeksolutions.com/", "_self");
    window.close();
  };

  const handleSecondaryButtonClick = () => navigate(ROUTE_CONSTANTS.DASHBOARD);

  useEffect(() => {
    const skillData = getSkillDataTable(reduxProfileData);
    setSkillTableData(skillData);
  }, [reduxProfileData]);
  return (
    <Grid container justifyContent="space-between">
      <Grid item md={5.65} lg={5.65} xl={5.65}>
        <Typography variant="subtitle1" sx={styles.desc1}>
          Thank you for reaching out! We are putting things together and will get back within 2
          working days
        </Typography>
        <Typography variant="body1" sx={styles.desc2}>
          Psst psst.... we have created a dashboard for you to track these openings and a lot more
          goodies once you onboard our team members.
        </Typography>
        <CustomButtonGroup
          primaryButtonText="Go to Home"
          secondaryButtonText="Go to Dashboard"
          isPrimaryButtonVisible
          isSecondaryButtonVisible
          onPrimaryButtonClick={handlePrimaryButtonClick}
          onSecondaryButtonClick={handleSecondaryButtonClick}
          buttonContainerStyles={{
            justifyContent: "center",
            gap: "36px",
            marginTop: "60px"
          }}
        />
      </Grid>
      <Grid item md={5.65} lg={5.65} xl={5.65}>
        <Typography variant="subtitle1" sx={styles.desc3}>
          {reduxUserData?.companyName?.label ? reduxUserData?.companyName?.label : ""}
        </Typography>
        <Map />
        {skillTableData.length > 0 && <Requirement data={skillTableData} />}
      </Grid>
    </Grid>
  );
}

export default Success;
