import React from "react";
import { Box } from "@mui/material";
import OTPInput from "otp-input-react";

function OtpInput({ OTP, setOTP }) {
  const styles = {
    root: {
      height: "3.75rem",
      width: "3.75rem",
      border: "1px solid silver",
      borderRadius: "10px",
      marginBottom: "10vh !important"
      // boxShadow: "inset 0px 0px 3px rgba(0, 0, 0, 0.25)",
    },
    resend: {
      marginTop: "3.125rem",
      color: "#003459",
      background: "white",
      minWidth: "4rem",
      minHeight: "0.1rem !important",
      "& button": {
        background: "red"
      }
    },
    boxStyle: {
      marginBottom: "10px",
      "& > div": {
        justifyContent: "space-between",
        "& > input": {
          marginRight: "0 !important"
        }
      }
    }
  };
  return (
    <Box sx={styles.boxStyle}>
      <OTPInput
        value={OTP}
        onChange={setOTP}
        OTPLength={6}
        otpType="number"
        disabled={false}
        inputStyles={styles.root}
      />
    </Box>
  );
}

export default OtpInput;
