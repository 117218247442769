// external imports
import React from "react";
import { Box, Chip, Stack, Typography } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const styles = {
  box: {
    p: 3,
    mb: 2,
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid grey",
    borderRadius: "10px"
  },
  box2: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    mb: 1.5
  },
  colorBlue: {
    color: "#217ff7"
  }
};

// Role Component
const Role = ({ title, requestedOn, no_of_folks_required: count }) => (
  <Box sx={styles.box}>
    <Stack spacing={2}>
      <Typography variant="h4">{title}</Typography>
      <Box sx={[styles.box2, styles.colorBlue]}>
        <CalendarMonthIcon sx={styles.icon} />
        <Typography variant="body1">Requested on: {requestedOn}</Typography>
      </Box>
    </Stack>
    <Chip icon={<GroupIcon />} label={count || 0} color="primary" />
  </Box>
);

export default Role;
