import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";

function ResendOTP({ resetTimer, handleResendOTP, counter, setCounter }) {
  const styles = {
    linkStyle: {
      color: "#00A8E8",
      textDecoration: "none",
      cursor: "pointer",
      fontSize: "16px"
    },
    disabledLink: {
      pointerEvents: "none",
      color: "#CFCFCF",
      pointer: "cursor",
      textDecoration: "none",
      fontSize: "16px"
    },
    containerStyle: {
      background: "#E6E6E6",
      marginTop: "34px",
      fontSize: "17px",
      padding: "20px 40px",
      borderRadius: "10px"
    },
    desc1: {
      lineHeight: "24px",
      textAlign: "justify"
    }
  };
  useEffect(() => {
    setCounter(30);
    const timer = setInterval(() => {
      setCounter((oldProgress) => {
        if (oldProgress <= 0) {
          return 0;
        }
        const diff = 1;

        return Math.max(oldProgress - diff, 0);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [resetTimer]);

  return (
    <Grid
      container
      direction="column"
      sx={styles.containerStyle}
      justifyContent="center"
      alignItems="center">
      <Grid item>
        <Typography variant="subtitle2" sx={styles.desc1}>
          Please enter the OTP to proceed
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" sx={styles.desc1}>
          Didn&apos;t receive it?{" "}
          <Button
            style={counter > 0 ? styles.disabledLink : styles.linkStyle}
            onClick={handleResendOTP}
            href="#text-buttons"
            disabled={counter > 0}>
            REQUEST AGAIN
          </Button>{" "}
          in {counter} secs
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ResendOTP;
