export const getTransition = (direction) => {
  let initialPosition = { y: 100, opacity: 0.25 };
  let animateTo = { y: 0, opacity: 1 };
  switch (direction) {
    case "top-bottom":
      initialPosition = { y: -100, opacity: 0.25 };
      break;
    case "bottom-top":
      break;
    case "left-right":
      initialPosition = { x: -100, opacity: 0.25 };
      animateTo = { x: 0, opacity: 1 };
      break;
    case "right-left":
      initialPosition = { x: 100, opacity: 0.25 };
      animateTo = { x: 0, opacity: 1 };
      break;
    default:
      break;
  }

  const transConfig = {
    initialPosition,
    animateTo
  };
  return transConfig;
};
