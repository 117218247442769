import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  AdvAutoComplete,
  CustomButtonGroup,
  FormTable
} from "@teamgeeksolution/components-library";
import {
  headerConfig,
  joiningTimeOptions,
  noOfFolksOptions,
  titleOptions
} from "../hiringFormHelper";

const styles = {
  dropDownStyle: {
    width: "76vw",
    marginBottom: "65px",
    height: "68px",
    "& .Mui-focused": {
      color: "#00171F !important"
    },
    "& .MuiSelect-select": {
      height: "3.5rem !important"
    },
    "& .MuiFormHelperText-root": {
      color: "#000000",
      marginLeft: 0
    }
  },
  newDropDownStyle: {
    marginBottom: "36px"
    // marginBottom: "87px",
    // height: "68px",
    // "& .Mui-focused": {
    //   color: "#00171F !important",
    // },
    // "& .MuiSelect-select": {
    //   height: "3.5rem !important",
    // },
    // "& .MuiFormHelperText-root": {
    //   color: "#000000",
    //   marginLeft: 0,
    // },
  },
  joiningInfoDropDown: {
    marginBottom: "32px"
  },
  addIcon: {
    fontSize: "40px",
    marginTop: "2vh",
    background: "silver",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "13vw   ",
    color: "white",
    pointer: "cursor",
    "&:hover": {
      backgroundColor: "primary.main"
    }
  },
  desc1: {
    lineHeight: "24px",
    marginBottom: "20px",
    textAlign: "justify"
  },
  desc2: {
    color: "#FF6699",
    lineHeight: "20px",
    textAlign: "justify"
  }
};

function ProfileFormComponent({
  selectedCard,
  data,
  handleAddClick,
  skillListData,
  handleAddSkill,
  handleDeleteSkill,
  handleSkillChange,
  handleProfileDataChange,
  skillList,
  reduxProfileData,
  error,
  handleSkip
}) {
  const renderProfileForm = () => {
    const getIconValidation = () => {
      if ((skillListData[selectedCard] || []).length > 0) {
        return !skillListData[selectedCard].every((skillItem) => skillItem.skill);
      }
      return false;
    };

    return (
      <Grid container mt={4.9}>
        <Grid item md={12} lg={12} xl={12}>
          <AdvAutoComplete
            helperText="Select the title of this position. Example : Senior ReactJS Developer, MERN developer"
            placeholder="Title"
            sxProps={styles.newDropDownStyle}
            fullWidth
            autoCompleteProps={{
              defaultValue: null,
              value: data[selectedCard]?.title
            }}
            name="title"
            defaultValue={null}
            options={titleOptions}
            onChange={(e) => handleProfileDataChange(e, "title", selectedCard)}
            error={error[selectedCard]?.title}
          />
        </Grid>
        <Grid item md={12} lg={12} xl={12}>
          <AdvAutoComplete
            helperText="Select the total number of team members you are seeking for this profile"
            placeholder="Number of folks required"
            sxProps={styles.newDropDownStyle}
            fullWidth
            autoCompleteProps={{
              defaultValue: data[selectedCard]?.no_of_folks_required,
              value: data[selectedCard]?.no_of_folks_required
            }}
            name="no_of_folks_required"
            defaultValue={reduxProfileData[selectedCard]?.no_of_folks_required}
            options={noOfFolksOptions}
            onChange={(e) => handleProfileDataChange(e, "no_of_folks_required", selectedCard)}
            error={error[selectedCard]?.no_of_folks_required}
          />
        </Grid>
        <Grid item md={12} lg={12} xl={12}>
          <AdvAutoComplete
            helperText="How soon are you to onboard these team members?"
            placeholder="Joining Time"
            sxProps={styles.joiningInfoDropDown}
            fullWidth
            autoCompleteProps={{
              defaultValue: data[selectedCard]?.joining_time,
              value: data[selectedCard]?.joining_time
            }}
            name="no_of_folks_required"
            defaultValue={reduxProfileData[selectedCard]?.joining_time}
            options={joiningTimeOptions}
            onChange={(e) => handleProfileDataChange(e, "joining_time", selectedCard)}
            error={error[selectedCard]?.joining_time}
          />
        </Grid>

        <Grid item md={12} lg={12} xl={12}>
          <FormTable
            headerConfig={headerConfig(skillList)}
            rowData={skillListData[selectedCard]}
            handleChange={handleSkillChange}
            onAddBtnClick={handleAddSkill}
            onDelete={handleDeleteSkill}
            helperText="Add all the skills you need for this profile. We have taken the liberty to add a few skills based on the job title. Feel free to edit them"
            addBtnDisabled={getIconValidation()}
          />
        </Grid>
      </Grid>
    );
  };

  const renderNoSkillAdded = () => (
    <Grid item justifyContent="center" alignItems="item">
      <Typography variant="subtitle1" sx={styles.desc1}>
        You can add as many profiles as you like along with the number of team members required.
        Click on &apos;Add profile&apos; to get started
      </Typography>
      <Typography variant="body1" sx={styles.desc2}>
        PS: Please try to create elaborate requirements here.The more details you enter, the better
        match we will be able to provide.
      </Typography>
      <CustomButtonGroup
        primaryButtonText="Add profile"
        secondaryButtonText="Skip"
        isPrimaryButtonVisible
        isSecondaryButtonVisible
        onPrimaryButtonClick={handleAddClick}
        onSecondaryButtonClick={handleSkip}
        buttonContainerStyles={{
          justifyContent: "center",
          gap: "36px",
          marginTop: "60px"
        }}
      />
    </Grid>
  );

  return <Box>{selectedCard !== null ? renderProfileForm() : renderNoSkillAdded()}</Box>;
}

export default ProfileFormComponent;
