import React from "react";
import "./index.css";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Header } from "@teamgeeksolution/components-library";
import { Grid } from "@mui/material";
import { Provider } from "react-redux";
import AllRoutes from "./routes/routeConfig";
import ThemeCustomizer from "./theme/Index";
import VerticalNav from "./components/custom/verticalNav/VerticalNav";
import "./App.css";
import store from "./store/index";
import TGSLogo from "./assests/images/TGSLogo.png";
import { TGS_WEBSITE_URL } from "./utils/constants";
import { ROUTE_CONSTANTS } from "./constants";

import "./assests/fonts/NeueHaasDisplayBlack.ttf";
import "./assests/fonts/NeueHaasDisplayBlackItalic.ttf";
import "./assests/fonts/NeueHaasDisplayBold.ttf";
import "./assests/fonts/NeueHaasDisplayBoldItalic.ttf";
import "./assests/fonts/NeueHaasDisplayLight.ttf";
import "./assests/fonts/NeueHaasDisplayLightItalic.ttf";
import "./assests/fonts/NeueHaasDisplayMediu.ttf";
import "./assests/fonts/NeueHaasDisplayMediumItalic.ttf";
import "./assests/fonts/NeueHaasDisplayRoman.ttf";
import "./assests/fonts/NeueHaasDisplayRomanItalic.ttf";
import "./assests/fonts/NeueHaasDisplayThin.ttf";
import "./assests/fonts/NeueHaasDisplayThinItalic.ttf";
import "./assests/fonts/NeueHaasDisplayXThin.ttf";
import "./assests/fonts/NeueHaasDisplayXThinItalic.ttf";
import "./assests/fonts/NeueHaasDisplayXXThin.ttf";
import "./assests/fonts/NeueHaasDisplayXXThinItalic.ttf";

const styles = {
  root: {
    marginTop: "15vh"
  }
};

const Main = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Header
        logoImage={TGSLogo}
        onLogoClick={() => {
          window.location.href = TGS_WEBSITE_URL;
        }}
      />
      <Grid container>
        <Grid item xs={1} />
        {pathname !== ROUTE_CONSTANTS.SUCCESS && pathname !== ROUTE_CONSTANTS.DASHBOARD && (
          <Grid item xs={1}>
            <VerticalNav />
          </Grid>
        )}
        <Grid
          item
          xs={
            pathname !== ROUTE_CONSTANTS.SUCCESS && pathname !== ROUTE_CONSTANTS.DASHBOARD ? 9 : 10
          }
          sx={styles.root}>
          <AllRoutes />
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
};

const App = () => (
  <Provider store={store}>
    <ThemeCustomizer>
      <Router basename="/">
        <Main />
      </Router>
    </ThemeCustomizer>
  </Provider>
);

export default App;
