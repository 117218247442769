import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 38,
  height: 16,
  padding: 0,
  display: "flex",
  "& .Mui-checked .MuiSwitch-thumb": {
    backgroundColor: "#88959E"
  },
  "& .MuiSwitch-switchBase": {
    padding: "2px 2px 2px 3px",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#93B4C1"
      }
    }
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    transition: theme.transitions.create(["width"], {
      duration: 200
    })
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box"
  }
}));

export default CustomSwitch;
