/* eslint-disable no-unused-vars */
// external imports
import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { FormHandler } from "@teamgeeksolution/components-library";

// internal imports
import Role from "./Role";

// Mock
import MockData from "./MockData";
import { getCookieValue, useNavigateUtil } from "../../utils";
import { ROUTE_CONSTANTS } from "../../constants";
import { REQUIREMENT_DATA_STR } from "../../utils/constants";

const phoneInputStyles = {
  boxShadow: "inset 0px 0px 3px rgb(0 0 0 / 25%)",
  border: "none",
  outline: "none",
  height: "4rem",
  color: "#000",
  width: "100%"
};

const styles = {
  box: {
    display: "flex"
  },
  box1: {
    flex: 2
  },
  box2: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > form": {
      width: "100%"
    }
  },
  title: {
    mb: 5
  },
  titleBox: {
    fontWeight: "bold"
  },
  noData: {
    color: "grey"
  },
  typography1: {
    mb: 10
  },
  colorBlue: {
    color: "#217ff7"
  }
};

const formData = {
  isPrimaryButtonVisible: true,
  isSecondaryButtonVisible: false,
  onSecondaryButtonClick: () => {},
  onPrimaryButtonClick: () => {},
  primaryButtonText: "Request a Callback",
  primaryButtonProps: {
    fullWidth: true
  }
  // fields: [
  //   {
  //     id: "phone_number",
  //     label: "",
  //     placeholder: "Phone Number",
  //     type: "phone",
  //     columnCount: 12,
  //     inputStyle: phoneInputStyles
  //   }
  // ]
};

// Dashboard Page
const Dashboard = () => {
  const navigate = useNavigateUtil();

  // This logic is to be improvised with token (now token is not storing in the local storage, so checking company id)
  const storedData = JSON.parse(localStorage.getItem(REQUIREMENT_DATA_STR));
  const companyId = storedData?.userData?.companyName?.company_master_id;
  // Rework on this condition
  const storedFirstName = storedData?.userData?.first_name;

  // enable this only the login flow implemented
  // const userToken = getCookieValue("UAT");
  // const userID = getCookieValue("UID");

  const storedProfiles = storedData?.profileData;

  // setProfiles would be used when go with api call
  const [profiles, setProfiles] = useState(companyId ? storedProfiles : MockData?.profiles);
  const [firstName, setFirstName] = useState(companyId ? storedFirstName : MockData?.firstName);

  const onSubmit = (data) => {
    console.warn("onSubmit - data >>", data);
    navigate(ROUTE_CONSTANTS.REQUIREMENTS);
  };

  return (
    <Box>
      <Typography variant="h5" sx={styles.title}>
        <Box component="span" sx={styles.titleBox}>
          Hi {firstName},{" "}
        </Box>
        Welcome Back!
      </Typography>
      <Stack direction="row" spacing={10} sx={styles.stack}>
        <Box sx={styles.box1}>
          {profiles &&
            profiles.length > 0 &&
            profiles.map((item, index) => <Role key={`role-${index + 1}`} {...item} />)}

          {profiles && profiles.length === 0 && (
            <Typography variant="h6" sx={styles.noData}>
              No profiles are added
            </Typography>
          )}
        </Box>
        <Box sx={styles.box2}>
          <Typography variant="body2" align="center" sx={[styles.typography1, styles.colorBlue]}>
            Incase you wish to discuss fresh requirements, please click on the button below. Our
            team will get in touch with you shortly.
          </Typography>
          <FormHandler formData={formData} onSubmit={onSubmit} />
        </Box>
      </Stack>
    </Box>
  );
};

export default Dashboard;
