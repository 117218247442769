import { useNavigate, useLocation } from "react-router-dom";

export const useNavigateUtil = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (endpoint) => {
    navigate(endpoint, { state: { from: location } });
  };
};

export const getCookieValue = (name) =>
  document.cookie
    ?.split("; ")
    ?.find((row) => row.startsWith(`${name}=`))
    ?.split("=")[1];

export const getFormattedDate = (date = "") => {
  const newDate = date ? new Date(date) : new Date();
  const day =
    parseInt(newDate.getDate(), 10) > 9
      ? parseInt(newDate.getDate(), 10).toString()
      : `0${parseInt(newDate.getDate(), 10)}`;
  const month =
    parseInt(newDate.getMonth() + 1, 10) > 9
      ? parseInt(newDate.getMonth() + 1, 10).toString()
      : `0${parseInt(newDate.getMonth() + 1, 10)}`; // months from 1-12
  const year = parseInt(newDate.getFullYear(), 10).toString();

  return `${day}/${month}/${year}`;
};
