import React from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

// import { getTransition } from "../../utils/helpers";
import { getTransition } from "./animateHelper";
import { ROUTE_CONSTANTS } from "../../../constants";

const getParams = (search) =>
  new Proxy(new URLSearchParams(search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });

const routes = [ROUTE_CONSTANTS.TIMEZONE, ROUTE_CONSTANTS.REQUIREMENTS, ROUTE_CONSTANTS.COMPANY];

const getDirection = (prev, current, animateXAxis) => {
  if (animateXAxis) {
    const { selected: prevSelected } = getParams(prev.search);
    const { selected: currSelected } = getParams(current.search);

    if (prevSelected > currSelected || prevSelected === "addNew") return "left-right";
    if (prevSelected < currSelected || currSelected === "addNew") return "right-left";
    return "right-left";
  }

  const currIdx = routes.findIndex((route) => route === current?.pathname);
  const prevIdx = routes.findIndex((route) => route === prev?.pathname);
  return currIdx - prevIdx < 0 ? "top-bottom" : "bottom-tops";
};

function Animate({ children, animateXAxis }) {
  const location = useLocation();
  const prev = location?.state?.from || {};

  const direction = getDirection(prev, location, animateXAxis);

  const { initialPosition, animateTo } = getTransition(direction);

  return (
    <motion.div
      key={location.pathname + location.search}
      initial={initialPosition}
      animate={animateTo}
      transition={{
        ease: "easeInOut",
        duration: 0.575
      }}>
      {children}
    </motion.div>
  );
}

export default Animate;
