import React, { useEffect, useRef, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import {
  FormHandler,
  PhoneNumberInput,
  AdvAutoComplete,
  Loader
} from "@teamgeeksolution/components-library";
import { Button, Grid, Box, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  designationList,
  getIndustriesList,
  getOTPRequestJSON,
  getOTPVerifyRequestJSON,
  getRequestBody,
  getSkillDataTable,
  userStyles as styles
} from "../hiringFormHelper";
import OtpInput from "../../components/common/otpInput/OtpInput";
import ResendOTP from "../../components/common/otpInput/ResendOTP";
import { requestCompanyList, requestOTP, startHiringApiCall } from "../../services/services";
import { setUserReduxData } from "../../store/reducers.js/startHiringSlice";
import Animate from "../../components/common/animate/Animate";
import { useNavigateUtil } from "../../utils";
import { ROUTE_CONSTANTS } from "../../constants";
import Map from "../../components/custom/map/Map";
import { REQUIREMENT_DATA_STR, validationSchema } from "../../utils/constants";
import Requirement from "../../components/custom/requirement/Requirement";

function UserComponent() {
  const navigate = useNavigateUtil();
  const dispatch = useDispatch();

  const refToTop = useRef < HTMLInputElement > null;
  useEffect(() => {
    if (refToTop.current) refToTop.current.scrollIntoView();
  });
  const startHiringData = useSelector((state) => state.startHiring);
  const {
    userData: reduxUserData,
    skillList: reduxSkillData,
    profileData: reduxProfileData,
    timeZone: reduxTimeZoneData
  } = startHiringData;
  const [userData, setUserData] = useState(reduxUserData);
  const [skillTableData, setSkillTableData] = useState([]);
  const [otpSend, setOtpSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [OTP, setOTP] = useState("");
  const [otpHelperText, setOtpHelperText] = useState("");
  const [counter, setCounter] = useState(0);

  const [validatedMobileNumbers, setValidatedMobileNumbers] = useState([]);
  const [showHelperText, setShowHelperText] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(reduxUserData?.phoneNumber || "");
  const [OTPverifyStatus, setOTPverifyStatus] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [companyName, setCompanyName] = useState(reduxUserData?.companyName || null);
  const [resetTimer, setResetTimer] = useState(false);
  const [otpSendCounter, setOtpSendCounter] = useState(0);
  const [error, setError] = useState({
    company: false,
    phone: false
  });
  const ref = useRef();

  useEffect(() => {
    window.scrollTo({
      top: ref.current
    });
  }, []);

  const getCompanyList = async () => {
    const resp = await requestCompanyList();
    if (resp.status) {
      const respCopy = JSON.parse(JSON.stringify(resp.result?.response));
      respCopy.forEach((companyItem, index) => {
        respCopy[index].label = companyItem.company_master_name;
        respCopy[index].value = companyItem.company_master_id;
      });
      setCompanyList(respCopy);
    }
  };

  useEffect(() => {
    if (companyList.length <= 0) {
      getCompanyList();
    }
  }, []);

  const handlePrimaryButtonClick = async (data) => {
    try {
      setLoading(true);
      const dataCopy = JSON.parse(JSON.stringify(data));
      dataCopy.companyName = companyName;
      dataCopy.phoneNumber = phoneNumber;

      const requestBody = getRequestBody(
        reduxTimeZoneData,
        reduxProfileData,
        reduxSkillData,
        dataCopy,
        phoneNumber,
        companyName
      );

      const resp = await startHiringApiCall(requestBody);

      setUserData(dataCopy);
      dispatch(setUserReduxData(dataCopy));
      const stateCopy = JSON.parse(JSON.stringify(startHiringData));
      stateCopy.userData = dataCopy;
      localStorage.setItem(REQUIREMENT_DATA_STR, JSON.stringify(stateCopy));
      if (resp.status) {
        navigate(ROUTE_CONSTANTS.SUCCESS);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSecondaryButtonClick = () => {
    navigate(ROUTE_CONSTANTS.REQUIREMENTS);
  };

  const fields = [
    {
      id: "first_name",
      placeholder: "First name",
      defaultValue: userData?.first_name,
      helperText: "Please enter your first name",
      type: "string",
      columnCount: 6,
      updateMf: false
    },
    {
      id: "last_name",
      placeholder: "Last name",
      defaultValue: userData?.last_name,
      helperText: "Please enter your last name",
      type: "string",
      columnCount: 6,
      updateMf: false
    },
    {
      id: "designation",
      placeholder: "Designation",
      helperText: "Please let us know what is your designation in the company",
      type: "autoComplete",
      autoCompleteProps: {
        defaultValue: userData?.designation || "",
        isOptionEqualToValue: (option, value) => option.id === value.id
      },
      columnCount: 12,
      updateMf: false,
      options: designationList,
      displayEmpty: true
    },
    {
      id: "industry",
      placeholder: "Industry",
      helperText: "Which industry does your company primarily operate in",
      type: "autoComplete",
      autoCompleteProps: {
        defaultValue: userData?.industry || "",
        isOptionEqualToValue: (option, value) => option.id === value.id
      },
      columnCount: 12,
      updateMf: false,
      options: getIndustriesList(),
      displayEmpty: true
    },
    {
      id: "company_email_id",
      helperText: "Please enter your company email id",
      placeholder: "Company Email",
      defaultValue: userData.company_email_id || "",
      type: "email",
      columnCount: 12,
      updateMf: false
    }
  ];

  useEffect(() => {
    const skillData = getSkillDataTable(reduxProfileData);
    setSkillTableData(skillData);
  }, [reduxProfileData]);

  const storedData = JSON.parse(localStorage.getItem(REQUIREMENT_DATA_STR));
  const storedPhNum = storedData?.userData?.phoneNumber || "";

  const getValidationError = () => {
    if (storedPhNum !== phoneNumber && !OTPverifyStatus) {
      return { phone: "Phone number is not verified" };
    }
    return {};
  };
  const formData = {
    validationSchema: validationSchema.company,
    isPrimaryButtonVisible: true,
    isSecondaryButtonVisible: true,
    buttonContainerStyles: {
      justifyContent: "center",
      marginTop: "36px"
    },
    primaryButtonText: "Complete",
    secondaryButtonText: "Back",
    primaryButtonType: "submit",
    // primaryButtonStyles: styles.primaryButtonStyle,
    secondaryButtonStyles: styles.secondaryButtonStyle,
    onSecondaryButtonClick: handleSecondaryButtonClick,
    defaultValuesObj: {
      designation: userData.designation,
      industry: userData.industry
    },
    validationErrors: getValidationError(),
    fields
  };

  const verifyOTP = async () => {
    const requestJSON = getOTPVerifyRequestJSON(phoneNumber, OTP);
    const resp = await requestOTP(requestJSON);
    if (resp.status) {
      if (resp.result.message === "verified successfully") {
        setOTPverifyStatus(true);
        setOtpHelperText("Thank you! You have successfully verified phone number");
        setShowHelperText(true);
        setOtpSend(false);
      } else {
        setOTPverifyStatus(false);
        setOtpHelperText("You have entered wrong OTP, please re enter it");
        setShowHelperText(true);
      }
    }
    setOTP("");
  };

  useEffect(() => {
    if (OTP.length >= 6) {
      verifyOTP();
    }
  }, [OTP]);

  const handleOTP = async () => {
    const requestJSON = getOTPRequestJSON(phoneNumber);
    const resp = await requestOTP(requestJSON);
    if (resp.status) {
      setOtpSend(true);
      setOtpSendCounter(otpSendCounter + 1);
      setOtpHelperText(
        "Thank you! We have sent an OTP to your number and email id. Please enter the same"
      );
      setShowHelperText(true);
      setResetTimer(!resetTimer);
    } else {
      setOtpSend(false);
    }
  };

  const handleResendOTP = async () => {
    const requestJSON = getOTPRequestJSON(phoneNumber, "RESEND");
    const resp = await requestOTP(requestJSON);
    if (resp.status) {
      setOtpSend(true);
      setOtpSendCounter(otpSendCounter + 1);
      setOtpHelperText(
        "Thank you! We have sent an OTP to your number and email id. Please enter the same"
      );
      setShowHelperText(true);
      setResetTimer(!resetTimer);
    } else {
      setOtpSend(false);
    }
  };

  const handleCompanyChange = (value) => {
    if (typeof value === "string") {
      setCompanyName({
        company_master_id: null,
        company_master_name: value,
        label: value,
        value: null
      });
      validationSchema.company_name
        .validate(value)
        .then(() => setError({ ...error, company: false }))
        .catch((err) => {
          if (err) {
            setError({ ...error, company: err });
          }
        });
    } else if (value && value.company_master_id) {
      // Create a new value from the user input

      setCompanyName({
        company_master_id: value.company_master_id,
        company_master_name: value.label,
        label: value.label,
        value: value.company_master_id
      });
      validationSchema.company_name
        .validate(value.label)
        .then(() => setError({ ...error, company: false }))
        .catch((err) => {
          if (err) {
            setError({ ...error, company: err });
          }
        });
    } else {
      setCompanyName(value);

      validationSchema.company_name
        .validate(value)
        .then((res) => setError({ ...error, company: res }))
        .catch((err) => {
          if (err) {
            setError({ ...error, company: err });
          }
        });
    }
  };

  const renderForm = () => (
    <Box>
      <Grid item>
        <AdvAutoComplete
          helperText="Let us know the company you represent"
          placeholder="Company Name"
          sxProps={styles.dropDownStyle}
          fullWidth
          allowNew
          value={companyName}
          autoCompleteProps={{
            defaultValue: companyName
          }}
          name="company"
          options={companyList}
          onChange={(value) => {
            handleCompanyChange(value);
          }}
          error={error.company}
        />
      </Grid>

      <FormHandler
        formData={formData}
        onSubmit={handlePrimaryButtonClick}
        columnSpacing={8}
        containerSxProps={styles.textFieldStyle}>
        <Grid item md={12} lg={12} xl={12}>
          <Stack direction="row" sx={styles.stack}>
            <PhoneNumberInput
              label=""
              value={phoneNumber}
              type="number"
              defaultValue={userData.phoneNumber}
              onChange={(value, data, event, formattedValue) => {
                const phoneNumberChange = formattedValue.split(" ").join("").split("-").join("");
                validationSchema.phone
                  .validate(phoneNumberChange)
                  .then(() => setError({ ...error, phone: false }))
                  .catch((err) => {
                    if (err) {
                      setError({ ...error, phone: err });
                    }
                  });
                if (
                  validatedMobileNumbers.some((item) => item === phoneNumberChange) ||
                  storedPhNum === phoneNumberChange
                ) {
                  setOTPverifyStatus(true);
                  setShowHelperText(true);
                } else if (phoneNumber !== phoneNumberChange && OTPverifyStatus) {
                  const currentList = [...validatedMobileNumbers];
                  currentList.push(phoneNumber);
                  setValidatedMobileNumbers(currentList); // pushing old verified mobile number into the list

                  setShowHelperText(false);
                  setOTPverifyStatus(false);
                  setCounter(0);
                }
                setPhoneNumber(phoneNumberChange);
              }}
              placeholder="Phone Number"
              helperText="Please enter your mobile number along with country code"
              // inputStyle={styles.phoneNumberStyle}
              inputStyle={styles.phoneInputStyles.inputStyle}
              containerStyle={styles.phoneInputStyles.containerStyle}
              buttonStyle={styles.phoneInputStyles.buttonStyle}
              dropdownStyle={styles.phoneInputStyles.dropdownStyle}
              searchStyle={styles.phoneInputStyles.searchStyle}
              error={error.phone}
            />
            <Button
              sx={styles.otpButtonStyle}
              disabled={
                phoneNumber.length <= 12 ||
                OTPverifyStatus ||
                counter > 0 ||
                validatedMobileNumbers.some((item) => item === phoneNumber) ||
                storedPhNum === phoneNumber
              }
              onClick={handleOTP}>
              Send Otp
            </Button>
          </Stack>

          {otpSend && (
            <Grid item sx={styles.otpContainer}>
              <OtpInput OTP={OTP} setOTP={setOTP} />
            </Grid>
          )}
          {showHelperText && otpHelperText && (
            <Box>
              <Typography variant="subtitle2" sx={styles.otpText}>
                {OTPverifyStatus && <DoneIcon sx={styles.doneIcon} />}
                {otpHelperText.split("Please")[0]}
                <br />
                {otpHelperText.includes("Please") && "Please"}
                {otpHelperText.split("Please")[1]}
              </Typography>
            </Box>
          )}
          {otpSend && (
            <Grid item>
              <ResendOTP
                resetTimer={resetTimer}
                handleResendOTP={handleResendOTP}
                counter={counter}
                setCounter={setCounter}
              />
            </Grid>
          )}
        </Grid>
      </FormHandler>
    </Box>
  );

  return (
    <Animate>
      {loading && <Loader />}
      <Grid container ref={ref} justifyContent="space-between">
        <Grid item md={5.65} lg={5.65} xl={5.65}>
          <Grid container direction="column">
            <Grid item>{renderForm()}</Grid>
          </Grid>
        </Grid>
        <Grid item md={5.65} lg={5.65} xl={5.65}>
          <Typography
            variant="subtitle1"
            sx={{
              ...styles.desc1,
              visibility: companyName?.label ? "visible" : "hidden"
            }}>
            {companyName?.label || "\u00A0"}
          </Typography>
          <Map />

          {skillTableData.length > 0 && <Requirement data={skillTableData} />}
        </Grid>
      </Grid>
    </Animate>
  );
}

export default UserComponent;
