import { createSlice } from "@reduxjs/toolkit";
import { REQUIREMENT_DATA_STR } from "../../utils/constants";

const initialStateObj = {
  timeZone: {
    label: "IST - India Standard Time(Kolkata)",
    value: "IST - India Standard Time(Kolkata)"
  },
  profileData: [],
  skillList: [
    [
      {
        skill: "",
        skillId: "",
        expertise: "",
        mustHaveFlag: false
      }
    ]
  ],
  cardCount: [],
  lineStatus: "",
  showLine: "none",
  userData: {},
  selectedCard: null
};

const initialState = JSON.parse(localStorage.getItem(REQUIREMENT_DATA_STR)) || initialStateObj;

export const startHiringSlice = createSlice({
  name: "startHiring",
  initialState,
  reducers: {
    setReduxTimeZone: (state, action) => {
      state.timeZone = action.payload;
    },
    setReduxProfileData: (state, action) => {
      const { profileData, skillListData, cardCount } = action.payload;
      state.profileData = profileData;
      state.skillList = skillListData;
      state.cardCount = cardCount;
    },
    setReduxProfile: (state, action) => {
      state.profileData = action.payload;
    },
    setReduxSkill: (state, action) => {
      state.skillList = action.payload;
    },
    setReduxCard: (state, action) => {
      state.cardCount = action.payload;
    },
    setMapValues: (state, action) => {
      const { lineStatus, showLine } = action.payload;
      state.lineStatus = lineStatus;
      state.showLine = showLine;
    },
    setUserReduxData: (state, action) => {
      state.userData = action.payload;
    },
    setSelectedCardReduxData: (state, action) => {
      state.selectedCard = action.payload;
    },
    updateRequirementData: (state, action) => {
      const { selectedCard, profile, skill } = action.payload;
      state.selectedCard = selectedCard;
      state.profileData = profile;
      state.skillList = skill;
    }
  }
});

export const {
  setReduxTimeZone,
  setReduxProfileData,
  setReduxProfile,
  setReduxSkill,
  setReduxCard,
  setMapValues,
  setUserReduxData,
  setSelectedCardReduxData,
  updateRequirementData
} = startHiringSlice.actions;

export default startHiringSlice.reducer;
