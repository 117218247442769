import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../constants";
// import ProfileComponent from "../components/custom/hiringForm/Profile/ProfileComponent";
// import UserComponent from "../components/custom/hiringForm/user/UserComponent";
import Location from "../pages/location/Location";
import ProfileComponent from "../pages/Profile/ProfileComponent";
import Success from "../pages/success/Success";
import UserComponent from "../pages/user/UserComponent";
import Dashboard from "../pages/dashboard/Dashboard";
import { getCookieValue, useNavigateUtil } from "../utils";

const AllRoutes = () => {
  const navigate = useNavigateUtil();
  useEffect(() => {
    const userToken = getCookieValue("UAT");
    const userID = getCookieValue("UID");
    const { pathname } = window.location;

    if (
      pathname !== ROUTE_CONSTANTS.TIMEZONE &&
      pathname !== ROUTE_CONSTANTS.DASHBOARD &&
      !(userToken || userID)
    ) {
      navigate(ROUTE_CONSTANTS.TIMEZONE);
    }
  }, []);

  return (
    <Routes>
      <Route exact path={ROUTE_CONSTANTS.COMPANY} element={<UserComponent />} />
      <Route exact path={ROUTE_CONSTANTS.DASHBOARD} element={<Dashboard />} />
      <Route exact path={ROUTE_CONSTANTS.TIMEZONE} element={<Location />} />
      <Route exact path={ROUTE_CONSTANTS.REQUIREMENTS} element={<ProfileComponent />} />
      <Route exact path={ROUTE_CONSTANTS.SUCCESS} element={<Success />} />
      <Route path="/" element={<Navigate to={ROUTE_CONSTANTS.TIMEZONE} replace />} />
    </Routes>
  );
};

export default AllRoutes;
