import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomButtonGroup, CardsSlider } from "@teamgeeksolution/components-library";
import { Grid } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import Animate from "../../components/common/animate/Animate";
import { getSkillDataTable, profileObject, skillObject } from "../hiringFormHelper";
import ProfileFormComponent from "./ProfileFormComponent";
import { requestSkillList } from "../../services/services";
import {
  setReduxCard,
  setReduxProfile,
  setReduxProfileData,
  setReduxSkill,
  setSelectedCardReduxData,
  updateRequirementData
} from "../../store/reducers.js/startHiringSlice";
import Map from "../../components/custom/map/Map";
import { getFormattedDate, useNavigateUtil } from "../../utils";
import { ROUTE_CONSTANTS } from "../../constants";
import { validationSchema } from "../../utils/constants";
import Requirement from "../../components/custom/requirement/Requirement";

const styles = {
  iconStyles: {
    width: "35vw",
    // width: "100%",
    position: "static",
    padding: 0,
    margin: "0px !important",
    "&  > .MuiTabs-root > .MuiButtonBase-root ": {
      width: 16
    }
  },
  sxPropsObj: {
    tableCell: {
      height: "64px"
    },
    tableRow: {
      "& td:last-of-type": {
        borderRadius: "0px 10px 10px 0px",
        border: "solid 1px #E6E6E6",
        borderLeft: "none"
      },
      "& td:first-of-type": {
        border: "solid 1px #E6E6E6",
        borderRadius: "10px 0px 0px 10px",
        borderRight: "none"
      }
    },
    table: {
      borderSpacing: "0 1rem"
    }
  },
  desc1: {
    lineHeight: "24px",
    marginBottom: "20px",
    textAlign: "justify"
  },
  cardSliderContainer: {
    marginTop: "-1rem"
  },
  buttonGroup: {
    marginTop: "16px"
  },
  mapStyle: {
    marginTop: "-4.5vh"
  }
};
const initialError = {
  title: "",
  no_of_folks_required: "",
  joining_time: ""
};

function ProfileComponent() {
  const navigate = useNavigateUtil();
  const dispatch = useDispatch();

  const [profileData, setProfileData] = useState([]);
  const [cardCount, setCardCount] = useState([]);
  const [selectedCard, setSelected] = useState(null);
  const [skillListData, setSkillListData] = useState([]);

  const [error, setError] = useState([initialError]);
  const reduxProfileData = useSelector((state) => state.startHiring.profileData);

  const skillTableData = getSkillDataTable(reduxProfileData);

  const reduxSkillData = useSelector((state) => state.startHiring.skillList);
  const reduxCardCountData = useSelector((state) => state.startHiring.cardCount);
  const reduxSelectedCard = useSelector((state) => state.startHiring.selectedCard);

  const [skillList, setSkillList] = useState([]);

  const getSkillList = async () => {
    const resp = await requestSkillList();
    if (resp.status) {
      const respCopy = JSON.parse(JSON.stringify(resp.result.response));
      respCopy.forEach((skill, index) => {
        respCopy[index].label = skill.skill_master_name;
        respCopy[index].value = skill.skill_master_name;
      });
      setSkillList(respCopy);
    }
  };

  useEffect(() => {
    // Fetching Skill's list from backend
    getSkillList();
  }, []);

  const handleAddClick = () => {
    const newSkillObject = JSON.parse(JSON.stringify(skillObject));
    const newProfileObject = JSON.parse(JSON.stringify(profileObject));
    newProfileObject.requestedOn = getFormattedDate();
    setProfileData([...profileData, newProfileObject]);
    setSkillListData([...skillListData, [newSkillObject]]);
    const cardCountTemp = [...cardCount, { id: cardCount.length + 1, desc: "" }];
    setCardCount(cardCountTemp);
    dispatch(setReduxCard(cardCountTemp));
    setSelected(cardCount.length);
    dispatch(setSelectedCardReduxData(cardCount.length));
    if (selectedCard !== null || selectedCard !== 0) {
      setError((prevState) => [...prevState, initialError]);
    }
  };

  const handleCardChange = (cardIndex) => {
    setSelected(cardIndex);
    dispatch(setSelectedCardReduxData(cardIndex));
  };

  const handleDeleteCard = (index) => {
    const cardCountCopy = JSON.parse(JSON.stringify(cardCount));
    const profileDataCopy = JSON.parse(JSON.stringify(profileData));
    const skillDataCopy = JSON.parse(JSON.stringify(skillListData));
    cardCountCopy.splice(index, 1);
    profileDataCopy.splice(index, 1);
    skillDataCopy.splice(index, 1);
    dispatch(
      updateRequirementData({
        selectedCard: cardCountCopy.length,
        profile: profileDataCopy,
        skill: skillDataCopy
      })
    );

    setCardCount(cardCountCopy);
    setProfileData(profileDataCopy);
    setSkillListData(skillDataCopy);
    if (cardCountCopy.length > 0) {
      setSelected(0);
    } else {
      setSelected(null);
    }
    setError((prevState) => {
      const prevCopy = JSON.parse(JSON.stringify(prevState));
      prevCopy.splice(index, 1);
      return prevCopy;
    });
  };

  const iconData = [
    {
      id: 1,
      icon: CheckBoxOutlinedIcon,
      innerText: "Select",
      onClick: (index) => {
        setSelected(index);
        dispatch(setSelectedCardReduxData(index));
      }
    },

    {
      id: 2,
      icon: DeleteOutlineOutlinedIcon,
      innerText: "Delete",
      onClick: (index) => handleDeleteCard(index)
    }
  ];

  const handleAddSkill = () => {
    const skillListCopy = JSON.parse(JSON.stringify(skillListData));
    skillListCopy[selectedCard].push(skillObject);
    setSkillListData(skillListCopy);
  };

  const handleDeleteSkill = (index) => {
    const skillListCopy = JSON.parse(JSON.stringify(skillListData));
    skillListCopy[selectedCard].splice(index, 1);
    setSkillListData(skillListCopy);
  };

  const handleSkillChange = (value, index, id) => {
    const skillCopy = JSON.parse(JSON.stringify(skillListData));
    skillCopy[selectedCard][index][id] = value;
    if (id === "skill") {
      skillCopy[selectedCard][index].skillId = value.skill_master_id;
    }
    setSkillListData([...skillCopy]);
    dispatch(setReduxSkill(skillCopy));
  };

  const handleProfileDataChange = (e, name, index) => {
    const profileDataCopy = JSON.parse(JSON.stringify(profileData));
    profileDataCopy[index][name] = e?.value || "";
    setProfileData(profileDataCopy);
    validationSchema.requirements[name]
      .validate(profileDataCopy[index][name])
      .then(() => {
        setError((prev) => {
          const errArr = [...prev];
          errArr[index][name] = "";
          return errArr;
        });
      })
      .catch((err) => {
        setError((prev) => {
          const errArr = [...prev];
          errArr[index][name] = { message: err.message };
          return errArr;
        });
      });

    dispatch(setReduxProfile(profileDataCopy));
  };

  const handlePrimaryButtonClick = () => {
    if (selectedCard === null) {
      navigate(ROUTE_CONSTANTS.COMPANY);
    }
    Object.keys(validationSchema.requirements).forEach((key) => {
      validationSchema.requirements[key]
        .validate(profileData[selectedCard][key])
        .then(() => {
          setError((prev) => {
            const errArr = [...prev];
            errArr[selectedCard][key] = "";
            return errArr;
          });
          dispatch(setReduxProfileData({ profileData, skillListData, cardCount }));
          navigate(ROUTE_CONSTANTS.COMPANY);
        })
        .catch((err) => {
          setError((prev) => {
            const errArr = [...prev];
            errArr[selectedCard][key] = { message: err.message };
            return errArr;
          });
        });
    });
  };

  const handleSecondaryButtonClick = () => {
    navigate(ROUTE_CONSTANTS.TIMEZONE);
  };

  useEffect(() => {
    if (reduxProfileData.length > 0) {
      setProfileData(reduxProfileData);
    }
  }, [reduxProfileData]);

  useEffect(() => {
    if (reduxSkillData.length > 0) {
      setSkillListData(reduxSkillData);
    }
  }, [reduxSkillData]);

  useEffect(() => {
    if (reduxCardCountData.length > 0) {
      setCardCount(reduxCardCountData);
      setSelected(reduxSelectedCard);
      dispatch(setSelectedCardReduxData(reduxSelectedCard));
    }
  }, [reduxCardCountData]);

  useEffect(() => {
    if (profileData.length > 0 && profileData[selectedCard]?.title) {
      const cardDetailCopy = JSON.parse(JSON.stringify(cardCount));
      cardDetailCopy[selectedCard].desc = profileData[selectedCard].title;
      setCardCount(cardDetailCopy);
    }
  }, [profileData]);

  return (
    <Animate>
      <Grid container justifyContent="space-between">
        <Grid item md={5.65} lg={5.65} xl={5.65}>
          <Grid container direction="column">
            {selectedCard !== null && (
              <Grid item sx={styles.cardSliderContainer}>
                <CardsSlider
                  data={cardCount}
                  containerStyles={styles.iconStyles}
                  showAddForm={cardCount.length < 5}
                  onAddFormClick={handleAddClick}
                  onChange={handleCardChange}
                  selected={selectedCard}
                  actionsVisible
                  iconData={iconData}
                />
              </Grid>
            )}
            <Grid item>
              <ProfileFormComponent
                selectedCard={selectedCard}
                data={profileData}
                handleAddClick={handleAddClick}
                skillListData={skillListData}
                handleAddSkill={handleAddSkill}
                handleDeleteSkill={handleDeleteSkill}
                handleSkillChange={handleSkillChange}
                handleProfileDataChange={handleProfileDataChange}
                skillList={skillList}
                reduxProfileData={reduxProfileData}
                error={error}
                handleSkip={handlePrimaryButtonClick}
              />
            </Grid>

            {cardCount.length > 0 && (
              <Grid item sx={styles.buttonGroup}>
                <CustomButtonGroup
                  primaryButtonText={selectedCard === cardCount.length - 1 ? "Save & Next" : "Save"}
                  secondaryButtonText="Back"
                  isPrimaryButtonVisible
                  isSecondaryButtonVisible
                  onPrimaryButtonClick={handlePrimaryButtonClick}
                  onSecondaryButtonClick={handleSecondaryButtonClick}
                  primaryButtonStyles={styles.primaryButtonStyles}
                  secondaryButtonStyles={styles.secondaryButtonStyle}
                  buttonContainerStyles={{
                    justifyContent: "center",
                    gap: "36px"
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item md={5.65} lg={5.65} xl={5.65} sx={styles.mapStyle}>
          <Map />
          {skillTableData.length > 0 && <Requirement data={skillTableData} />}
        </Grid>
      </Grid>
    </Animate>
  );
}

export default ProfileComponent;
