import { combineReducers, configureStore } from "@reduxjs/toolkit";
import startHiringReducer from "./reducers.js/startHiringSlice";

const reducer = combineReducers({
  startHiring: startHiringReducer
});

export default configureStore({
  reducer
});
