import { Grid, Typography } from "@mui/material";
import { TableList } from "@teamgeeksolution/components-library";
import React from "react";

const styles = {
  sxPropsObj: {
    tableCell: {
      height: "64px"
    },
    tableRow: {
      "& td:last-of-type": {
        borderRadius: "0px 10px 10px 0px",
        border: "solid 1px #E6E6E6",
        borderLeft: "none"
      },
      "& td:first-of-type": {
        border: "solid 1px #E6E6E6",
        borderRadius: "10px 0px 0px 10px",
        borderRight: "none"
      }
    },
    table: {
      borderSpacing: "0 1rem"
    }
  },
  desc1: {
    lineHeight: "24px",
    marginBottom: "20px",
    textAlign: "justify"
  },
  container: {
    marginTop: "3.75rem",
    marginLeft: "0.9rem"
  }
};

function Requirement({ data }) {
  return (
    <Grid item sx={styles.container}>
      <Typography variant="subtitle1" sx={styles.desc1}>
        Requirements
      </Typography>
      <TableList
        hideHeader
        hideFooter
        oddRowsColor="#E6E6E6"
        data={data}
        sxPropsObj={styles.sxPropsObj}
      />
    </Grid>
  );
}

export default Requirement;
