import { Navbar } from "@teamgeeksolution/components-library";
import React from "react";
import { ROUTE_CONSTANTS } from "../../../constants";
import { useNavigateUtil } from "../../../utils/index";
import { verticalNavbardata, VerticalNavbarstyles } from "./verticalNavHelper";

function VerticalNav() {
  const navigate = useNavigateUtil();

  const handleClick = (endpoint) => {
    navigate(endpoint);
  };

  const n = document.URL.lastIndexOf("/");
  const endpoint = document.URL.substring(n + 1);
  const route = document.URL.substring(n);

  return (
    <div style={VerticalNavbarstyles.root}>
      {route !== ROUTE_CONSTANTS.SUCCESS && (
        <Navbar
          data={verticalNavbardata}
          onClick={handleClick}
          currentEndpoint={`/${endpoint}`}
          gradient="linear-gradient(224.62deg, rgba(0, 52, 89, 1) 14.01%, rgba(0, 126, 167, 1) 34.58%, rgba(0, 168, 232, 1) 65.23%)"
          gradientOffsets={[
            { offset: "0%", stopColor: "#00A8E8" },
            { offset: "14.01%", stopColor: "#007EA7" },
            { offset: "42.34%", stopColor: "#007EA7" },
            { offset: "88.52%", stopColor: "#003459" }
          ]}
        />
      )}
    </div>
  );
}

export default VerticalNav;
