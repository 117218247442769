import { apiInstance, API_URL } from "./api";

export const startHiringApiCall = async (requestBody) => {
  try {
    const rs = await apiInstance({
      method: "POST",
      url: API_URL.NEW_START_HIRING,
      data: requestBody
    });

    const resp = { status: true, result: rs.data };
    return resp;
  } catch (error) {
    const resp = { status: false, result: error?.response?.data };
    return resp;
  }
};

export const requestSkillList = async () => {
  try {
    const rs = await apiInstance({
      method: "GET",
      url: API_URL.SKILL
    });

    const resp = { status: true, result: rs.data };
    return resp;
  } catch (error) {
    const resp = { status: false, result: error?.response?.data };
    return resp;
  }
};

export const requestCompanyList = async () => {
  try {
    const rs = await apiInstance({
      method: "GET",
      url: API_URL.COMPANY
    });

    const resp = { status: true, result: rs.data };
    return resp;
  } catch (error) {
    const resp = { status: false, result: error?.response?.data };
    return resp;
  }
};

export const requestOTP = async (requestBody) => {
  try {
    const rs = await apiInstance({
      method: "POST",
      url: API_URL.OTP,
      data: requestBody
    });

    const resp = { status: true, result: rs.data };
    return resp;
  } catch (error) {
    const resp = { status: false, result: error?.response?.data };
    return resp;
  }
};

export const requestCompanyDetails = async (companyId) => {
  try {
    const rs = await apiInstance({
      method: "GET",
      url: API_URL.COMPANY_DETAILS,
      params: {
        comapany_id: companyId
      }
    });

    const resp = { status: true, result: rs.data };
    return resp;
  } catch (error) {
    const resp = { status: false, result: error?.response?.data };
    return resp;
  }
};
