import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import { ROUTE_CONSTANTS } from "../../../constants";

export const verticalNavbardata = [
  {
    name: "Select Timezone",
    id: "location",
    selected: true,
    endpoint: ROUTE_CONSTANTS.TIMEZONE,
    Icon: LocationOnOutlinedIcon
  },
  {
    name: "Requirements",
    id: "requirments",
    selected: false,
    endpoint: ROUTE_CONSTANTS.REQUIREMENTS,
    Icon: Groups2OutlinedIcon
  },
  {
    name: "Company Details",
    id: "company",
    selected: false,
    endpoint: ROUTE_CONSTANTS.COMPANY,
    Icon: PersonOutlineOutlinedIcon
  }
];

export const VerticalNavbarstyles = {
  root: {
    marginTop: "15vh"
  }
};
