export default {
  firstName: "Roy",
  profiles: [
    {
      title: "MERN Developer",
      requestedOn: "07/07/2019",
      no_of_folks_required: 5
    },
    {
      title: "React Developer",
      requestedOn: "07/08/2019",
      no_of_folks_required: 2
    },
    {
      title: "Java Developer",
      requestedOn: "10/08/2019",
      no_of_folks_required: 3
    },
    {
      title: "Python Developer",
      requestedOn: "11/08/2019",
      no_of_folks_required: 9
    }
  ]
};
