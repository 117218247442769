import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, TextField } from "@mui/material";
import { DropdownInput } from "@teamgeeksolution/components-library";
import { industriesData } from "../assests/data/industriesData";
import CustomSwitch from "../components/custom/switch/CustomSwitch";

export const titleOptions = [
  { label: "Angular Developer", value: "Angular Developer" },
  { label: "React Developer", value: "React Developer" },
  { label: "MERN Developer", value: "MERN Developer" },
  { label: "FrontEnd Architect", value: "FrontEnd Architect" }
];

export const noOfFolksOptions = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" }
];

export const joiningTimeOptions = [
  { label: "Immediate", value: "Immediate" },
  { label: "15 days", value: "15 days" },
  { label: "1 month", value: "1 month" }
];

export const skillObject = {
  skill: null,
  skillId: null,
  expertise: null,
  mustHaveFlag: null
};

export const profileObject = {
  title: null,
  no_of_folks_required: null,
  joining_time: null
};

const expertiseOption = [
  { label: "Beginner", value: "Beginner" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Expert", value: "Expert" }
];

const TEXT_FIELD_STYLES = {
  fontSize: "1.125rem",
  color: "black",
  "& input:focus + fieldset": {
    border: "none"
  },
  ".MuiInputBase-root": {
    backgroundColor: "white",
    boxShadow: "inset 0px 0px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: "0.625rem",
    height: "4rem",
    fontSize: "1.125rem"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none"
  },
  "& .MuiInputLabel-root": {
    padding: "0 8px",
    marginTop: "0.656rem"
  },
  "& .MuiInputLabel-shrink": {
    marginTop: 0,
    backgroundColor: "white",
    padding: "0 8px"
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "gray"
  },
  "&::placeholder": {
    color: "background.defaultGrey2"
  },
  ".MuiInputBase-root.Mui-error": {
    border: "solid red 1px"
  }
};

export const headerConfig = (skillData) => [
  {
    columnlabel: "Skills",
    id: "skill",
    componentProps: {
      placeholder: "Skill",
      options: skillData,
      clearIcon: null,
      popupIcon: <KeyboardArrowDownIcon />,
      renderInput: (params) => <TextField {...params} sx={TEXT_FIELD_STYLES} />,
      OptionEqualToValue: (option, value) => option.skilld === value.skillId
    },
    component: Autocomplete,
    onChange: (_, newValue) => newValue,
    align: "left",
    width: "35%"
  },
  {
    columnlabel: "Expertise",
    id: "expertise",
    componentProps: {
      placeholder: "Expertise",
      options: expertiseOption
    },
    align: "left",
    width: "40%",
    component: DropdownInput,
    onChange: (e) => e.target.value
  },
  {
    columnlabel: "Must have?",
    id: "mustHaveFlag",
    componentProps: {
      placeholder: ""
    },
    align: "left",
    width: "25%",
    component: CustomSwitch,
    onClick: (event) => event.target.checked
  }
];

export const getSkillDataTable = (skills) => {
  const dataObject = [];
  skills.forEach((item, index) => {
    const obj = {
      id: index,
      textsArr: [
        {
          id: index,
          text: item.title
        },
        {
          id: index + 1,
          text: item.no_of_folks_required,
          align: "right"
        }
      ]
    };
    dataObject.push(obj);
  });
  return dataObject;
};
export const requirementData = [
  {
    id: 1,
    textsArr: [
      {
        id: 1,
        text: "Senior React Dev"
      },
      {
        id: 2,
        text: 3
      }
    ]
  },
  {
    id: 2,
    textsArr: [
      {
        id: 1,
        text: "Java dev"
      },
      {
        id: 2,
        text: 2
      }
    ]
  },
  {
    id: 3,
    textsArr: [
      {
        id: 1,
        text: "React  native dev"
      },
      {
        id: 2,
        text: 2
      }
    ]
  }
];

const getCompanyRequirement = (profileData, skillData) => {
  const companyRequirement = [];

  profileData.forEach((profileItem, index) => {
    const profileItemCopy = JSON.parse(JSON.stringify(profileItem));
    profileItemCopy.required_skills = skillData?.[index]?.map((item) => ({
      ...item,
      skill: item?.skill?.label
    }));
    companyRequirement.push(profileItemCopy);
  });

  return companyRequirement;
};

const getCompanyReprentativeData = (data, phoneNumber, companyName) => {
  const obj = {
    first_name: data.first_name,
    last_name: data.last_name,
    company_name: companyName.company_master_name,
    company_id: companyName.company_master_id,
    designation: data.designation.label,
    industry: data.industry.label,
    emailId: data.company_email_id,
    mobile_number: phoneNumber
  };
  return obj;
};

export const getRequestBody = (
  timeZoneData,
  profileData,
  skillData,
  userData,
  phoneNumber,
  companyName
) => ({
  time_zone: timeZoneData.value,
  company_requirement: getCompanyRequirement(profileData, skillData),
  company_representative_info: getCompanyReprentativeData(userData, phoneNumber, companyName)
});

export const getOTPRequestJSON = (phonenumber, OTPSTATE = "SENDOTP") => {
  const obj = {
    otpstate: OTPSTATE,
    phno: phonenumber
  };

  return obj;
};

export const getOTPVerifyRequestJSON = (phonenumber, OTP) => {
  const obj = {
    otpstate: "VERIFY",
    phno: phonenumber,
    otp: OTP
  };

  return obj;
};

export const handlePhoneDisable = (phoneNumber) => {
  if (phoneNumber.length >= 12) {
    return false;
  }
  return true;
};

export const userStyles = {
  primaryButtonStyle: {
    color: "#00171F",
    background: "white",
    border: "3px solid #00171F",
    borderRadius: "0px",
    minWidth: "15vw"
  },
  secondaryButtonStyle: {
    // color: "#00171F",
    // background: "white",
    // border: "hidden",
    // minWidth: "12vw",
    marginRight: "36px"
  },
  dropDownStyle: {
    marginBottom: "36px"
  },
  iconStyles: {
    minWidth: "35vw",
    position: "static"
  },
  sxPropsObj: {
    tableCell: {
      height: "64px"
    },
    tableRow: {
      "& td:last-of-type": {
        borderRadius: "0px 10px 10px 0px",
        border: "solid 1px #E6E6E6",
        borderLeft: "none"
      },
      "& td:first-of-type": {
        border: "solid 1px #E6E6E6",
        borderRadius: "10px 0px 0px 10px",
        borderRight: "none"
      }
    },
    table: {
      borderSpacing: "0 1rem"
    }
  },
  textFieldStyle: {},

  resendOTPButtonStyle: {
    color: "#003459",
    background: "white",
    marginLeft: "26vw",
    marginBottom: "4vh",
    border: "2px solid #003459",
    minWidth: "4rem",
    minHeight: "0.1rem !important",
    display: "inline !important",
    marginTop: "1vh",
    borderRadius: "0px"
  },
  stack: { position: "relative" },
  phoneInputStyles: {
    inputStyle: {
      border: "none",
      boxShadow: "inset 0px 0px 3px rgba(0, 0, 0, 0.25)",
      width: "70%",
      height: "4rem",
      position: "relative",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    buttonStyle: {
      background: "none",
      border: "none",
      paddingLeft: "0.25rem"
    },
    dropdownStyle: {},
    "& .react-tel-input": {
      ".selected-flag": {
        ".flag": {
          borderRadius: "20px"
        }
      }
    },
    helperTextStyles: {
      fontWeight: 700,
      ml: "0.5rem !important",
      mt: "0.75rem !important"
    }
  },
  otpButtonStyle: {
    background: "#00A8E8",
    color: "white",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 10,
    borderTopRightRadius: 10,
    height: "4rem !important",
    position: "absolute",
    right: 0,
    width: "30%",
    "&.Mui-disabled": {
      background: "#E6E6E6",
      color: "#CFCFCF"
    },
    ":hover": {
      bgcolor: "#007EA7",
      color: "white"
    }
  },
  desc1: {
    lineHeight: "24px",
    marginBottom: "36px",
    textAlign: "justify",
    marginTop: "-0.35rem",
    marginLeft: "0.9rem"
  },
  otpText: {
    font: "Segoe UI",
    fontSize: "1rem",
    display: "flex",
    alignItems: "center"
  },
  doneIcon: {
    color: "green",
    marginRight: "5px"
  },
  otpContainer: {
    marginTop: "36px"
  }
};

export const designationList = [
  {
    id: 1,
    value: "Chief Executive Officer",
    label: "Chief Executive Officer"
  },
  {
    id: 2,
    value: "Chief Financial Officer",
    label: "Chief Financial Officer"
  },
  {
    id: 3,
    value: "Chief Operating Officer",
    label: "Chief Operating Officer"
  },
  {
    id: 4,
    value: "Product Manager",
    label: "Product Manager"
  },
  {
    id: 5,
    value: "HR Manager",
    label: "HR Manager"
  },
  {
    id: 6,
    value: "Sales Manager",
    label: "Sales Manager"
  },
  {
    id: 7,
    value: "Marketing Manager",
    label: "Marketing Manager"
  },
  {
    id: 8,
    value: "Business Development Manager",
    label: "Business Development Manager"
  },
  {
    id: 9,
    value: "IT Manager",
    label: "IT Manager"
  },
  {
    id: 10,
    value: "Other",
    label: "Other"
  }
];

export const getIndustriesList = () => {
  const industriesList = industriesData();
  const list = [];
  industriesList.forEach((item, index) => {
    const obj = {
      id: index + 1,
      label: item,
      value: item
    };
    list.push(obj);
  });
  return list;
};
