export const timeZoneList = [
  {
    label: "DST - (UTC-12:00) International Date Line West",
    value: "DST - (UTC-12:00) International Date Line West"
  },
  {
    label: "IST - India Standard Time(Kolkata)",
    value: "IST - India Standard Time(Kolkata)"
  },
  {
    label: "CST - (UTC+04:00) Yerevan",
    value: "CST - (UTC+04:00) Yerevan"
  },
  {
    label: "EST - (UTC-05:00) Eastern Time (US & Canada)",
    value: "EST - (UTC-05:00) Eastern Time (US & Canada)"
  }
];
