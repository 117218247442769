import axios from "axios";

// All API URL's Here
export const API_URL = {
  SIGN_IN: "https://qxva9sbyrl.execute-api.us-east-1.amazonaws.com/dev/sign-in",
  SIGN_UP: "https://qxva9sbyrl.execute-api.us-east-1.amazonaws.com/dev/sign-up",
  START_HIRING: "https://j32gnva9b7.execute-api.us-east-1.amazonaws.com/dev/",
  NEW_START_HIRING: "https://qt6sdwztb3.execute-api.us-east-1.amazonaws.com/dev/start-hiring",
  SKILL: "https://imqcownrz8.execute-api.us-east-1.amazonaws.com/dev/tgs/hq-skill",
  COMPANY: "https://imqcownrz8.execute-api.us-east-1.amazonaws.com/dev/tgs/hq-company",
  COMPANY_DETAILS: "https://qt6sdwztb3.execute-api.us-east-1.amazonaws.com/dev/start-hiring",
  OTP: "https://imqcownrz8.execute-api.us-east-1.amazonaws.com/dev/tgs/hq-otp"
};

// Setting general api setting here
// TODO: Set base api here
export const apiInstance = axios.create({
  headers: { "Content-Type": "application/json" }
});
