import React, { useEffect, useState } from "react";
import { AdvAutoComplete, CustomButtonGroup } from "@teamgeeksolution/components-library";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Animate from "../../components/common/animate/Animate";
import { useNavigateUtil } from "../../utils/index";
import { timeZoneList } from "../../assests/data/timeZone";
import { timezones, validationSchema } from "../../utils/constants";
import { setMapValues, setReduxTimeZone } from "../../store/reducers.js/startHiringSlice";
import { ROUTE_CONSTANTS } from "../../constants";
import Map from "../../components/custom/map/Map";

function Location() {
  const [timezone, setTimeZone] = useState("");
  const [error, setError] = useState(false);

  const reduxTimeZoneData = useSelector((state) => state.startHiring.timeZone);

  useEffect(() => {
    setTimeZone(reduxTimeZoneData);
  }, [reduxTimeZoneData]);

  const navigate = useNavigateUtil();
  const dispatch = useDispatch();

  const handleTimeZoneChange = (event) => {
    setTimeZone(event);
    validationSchema.timezone
      .validate(event?.value || "")
      .then(() => setError(false))
      .catch((err) =>
        setError({
          message: err.message
        })
      );
  };

  const styles = {
    dropDownStyle: {
      "& .Mui-focused": {
        color: "#00171F !important"
      },
      // "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      //   transform: "translate(1vw, 0.4vh) scale(1);",
      // },
      "& .MuiFormHelperText-root": {
        color: "#000000",
        marginLeft: 0
      }
    },
    mapStyle: {
      marginTop: "-4.5vh"
    }
  };

  useEffect(() => {
    if (timezone) {
      const timeShortForm = timezone.value.split(" ")[0];
      const value = timezones[timeShortForm];
      dispatch(setMapValues({ lineStatus: value, showLine: "block" }));
    }
  }, [timezone]);

  const handlePrimaryButtonClick = () => {
    dispatch(setReduxTimeZone(timezone));
    navigate(ROUTE_CONSTANTS.REQUIREMENTS);
  };

  const getValidationError = () => {
    if (timezone) {
      return {};
    }
    return { timezone: false };
  };

  return (
    <Animate>
      <Grid container justifyContent="space-between">
        <Grid item md={5.65} lg={5.65} xl={5.65}>
          <Grid container direction="column">
            <Grid item>
              <AdvAutoComplete
                helperText="Select a time zone these members will be working in"
                placeholder="Pacific Time(US & Canada)"
                sxProps={styles.dropDownStyle}
                fullWidth
                value={timezone}
                autoCompleteProps={{
                  defaultValue: reduxTimeZoneData
                }}
                name="timezone"
                defaultValue={timezone}
                options={timeZoneList}
                onChange={handleTimeZoneChange}
                error={error}
              />
            </Grid>
            <Grid item>
              <CustomButtonGroup
                primaryButtonText="Save & Next"
                secondaryButtonText="Back"
                isPrimaryButtonVisible
                isSecondaryButtonVisible={false}
                validationErrors={getValidationError()}
                onPrimaryButtonClick={handlePrimaryButtonClick}
                buttonContainerStyles={{
                  justifyContent: "center",
                  marginTop: "60px"
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5.65} lg={5.65} xl={5.65} sx={styles.mapStyle}>
          <Map />
        </Grid>
      </Grid>
    </Animate>
  );
}

export default Location;
