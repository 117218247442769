import React from "react";
import { useSelector } from "react-redux";
import mapImage from "../../../assests/images/map_image.jpg";

function Map() {
  const reduxLineStatus = useSelector((state) => state.startHiring.lineStatus);

  const reduxShowLine = useSelector((state) => state.startHiring.showLine);

  const commonStyle = {
    position: "absolute",
    border: "0.4px solid black",
    transition: "all 0.8s ease-in-out"
  };

  const styles = {
    root: {
      position: "relative"
    },
    horizontalLine: {
      ...commonStyle,
      width: "96%",
      top: reduxLineStatus.top,
      left: "1vw"
    },
    verticalLine: {
      ...commonStyle,
      left: reduxLineStatus.left,
      right: reduxLineStatus.right,
      height: "88%",
      top: "4.5vh"
    }
  };

  return (
    <div style={styles.root}>
      <img src={mapImage} height="auto" width="100%" alt="map" />

      <div style={{ display: reduxShowLine }}>
        <div style={styles.horizontalLine} />
        <div style={styles.verticalLine} />
      </div>
    </div>
  );
}

export default Map;
